<template>
  <div>
    <el-button v-if="assessData" style="position: absolute; right: 140px; top: 50px;" size="medium" @click="dayin()">打印</el-button>
    <iframe v-if="assessData" id="pdfDom" :src="'https://www.apesk.com/lee/new/index_2_utf.asp?id=' + assessData.result"
      frameborder="0" />
    <div v-else>
      <div style="font-size: 180px;text-align: center;color: #e3e3e3;" @click="dialogVisible = true">+</div>
      <div class="invite" @click="dialogVisible = true">邀请学员进行测评</div>
    </div>

    <el-dialog :visible.sync="dialogVisible" title="测评链接" append-to-body width="700px">

      <div>
        {{ "https://daoshi.bkgaoshou.com/#/assess?id=" + userInfo.id }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="copyText">复制</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    userInfo: Object
  },
  data() {
    return {
      status: false,
      assessData: null,
      dialogVisible: false,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
    console.log(this.userInfo)
    this.$fecth.post("Appraisal/getAssess", { user_id: this.userInfo.id }).then((res) => {
      let { code, msg, data } = res;
      if (data) {
        this.assessData = data
      }
      console.log(code, msg, data)
    });
    
  },
  methods: {
    copyText() {
      let oInput = document.createElement("input"); //创建一个隐藏input（重要！）
      oInput.value =
        "https://daoshi.bkgaoshou.com/#/assess?id=" + this.userInfo.id
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      alert("复制成功");
      this.dialogVisible = false
    },
    dayin() {
       let url = 'https://www.apesk.com/lee/new/index_2_utf.asp?id=' + this.assessData.result
       window.open(url,'_tarage')
       console.log(url)
    }
  },
};
</script>

<style scoped lang='less'>
#pdfDom {
  width: 100%;
  min-height: 98vh;
}
本科一批

.invite {
  text-align: center;
  color: #5896f9;
  padding: 3px 7px;
  margin-bottom: 20px;
  margin-top: 40px;
}
</style>
